export default {
  title: {
    meta: {
        default_lang: "vi"
    },
    type: "translatable-text",
    value: null,
    translations: []
  },
  body: {
    meta: {
        default_lang: "vi"
    },
    type: "translatable-text",
    value: null,
    translations: []
  },
  agree_button_text: {
    meta: {
        default_lang: "vi"
    },
    type: "translatable-text",
    value: null,
    translations: []
  },
  mail_type: 1,
  receiver_type: 3
}
