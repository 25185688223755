<template>
  <div class="page-content pb-1">
    <page-breadcrumb title="Mail Management" class="mb-3" />

    <div class="d-flex align-items-center">
      <form-generator class="flex-grow-1" :model="filter"
        :schema="filter_schema"
      />
      <data-create-popup class="ml-1 mt-1"
        :schema="mail_schema" :default-data="default_object"
        :create-data-fn="createItem" @create="$refs.mail_list.getList()"
      />
    </div>
    <data-table-ssr id="mail_list" ref="mail_list"
      :columns="list_fields" :get-list-fn="getList"
      :to-edit-fn="toEditPage" :delete-row-fn="deleteItem"
    />
    <data-update-popup ref="mail_update_popup" title="Update Mail" :schema="mail_schema"
      :default-data="editing_data" :update-data-fn="updateItem"
    />

  </div>
</template>

<script>
import service from "../service"
import { MAIL_TYPES, MAIL_STATUS, BODY_TYPE_OPTIONS } from './../constants'
import { buildSelectOptions } from '@core/utils'
import default_object from './default_mail_object'

const MAIL_TYPE_OPTIONS = buildSelectOptions(MAIL_TYPES);
const MAIL_STATUS_OPTIONS = [
  { text: 'Private', value: 1, variant: 'primary' },
  { text: 'Public', value: 2, variant: 'success' },
  { text: 'Expired', value: 3, variant: 'danger' },
  { text: 'Default', value: 4, variant: 'secondary' },
];

const filter_schema = [
  {
    cols: 4,
    fields: [
      { label: 'Filter Date', field: 'time_range', input_type: 'date_range' },
    ]
  },
  {
    cols: 8,
    fields: [
      { label: 'Title', field: 'title' },
    ]
  }
];
const list_fields = [
  { label: 'Title', field: 'title', display_type: 'translatable-text' },
  { label: 'Mail Type', field: 'mail_type', input_type: 'select', options: MAIL_TYPE_OPTIONS },
  { label: 'Created At', field: 'createdAt', input_type: 'datetime' },
  { label: 'Status', field: 'status', input_type: 'select', options: MAIL_STATUS_OPTIONS },
];
const mail_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Title', field: 'title', input_type: 'ui-component' },
      { label: 'Body', field: 'body', input_type: 'ui-component' },
      { label: 'BodyType', field: 'body_type', input_type: 'select', options: BODY_TYPE_OPTIONS },
      { label: 'Type', field: 'mail_type', input_type: 'select', options: MAIL_TYPE_OPTIONS },
    ]
  },
  {
    displayCond(item) {
      return ![MAIL_TYPES.REWARD_GOGA_RANK, MAIL_TYPES.REWARD_LOYALTY_REFERRAL].includes(item.mail_type)
    },
    cols: 12,
    fields: [
      { label: 'Button', field: 'agree_button_text', input_type: 'ui-component' },
      { label: 'Redirect Url', field: 'redirect_url' },
    ]
  },
  {
    displayCond(item) {
      return [MAIL_TYPES.NOTIFICATION].includes(item.mail_type)
    },
    cols: 12,
    fields: [
      { label: 'SendingDate', field: 'sending_date', input_type: 'datetime' },
      { label: 'ExpiredDate', field: 'expired_date', input_type: 'datetime' },
    ]
  }
];

function getStatus(mail) {
  if (mail.mail_type !== MAIL_TYPES.NOTIFICATION) { return MAIL_STATUS.DEFAULT }
  const now = new Date()
  const sendingDate = new Date(mail.sending_date)
  const expiredDate = new Date(mail.expired_date)
  if (now < sendingDate) { return MAIL_STATUS.PRIVATE }
  if (now < expiredDate) { return MAIL_STATUS.PUBLIC }
  return MAIL_STATUS.EXPIRED
}

export default {
  data() {
    return {
      list_fields, filter_schema, mail_schema, default_object,
      editing_data: null,
      filter: {
        time_range: '',
        title: null,
      },
    };
  },
  watch: {
    filter: {
      handler() {
        this.$refs.mail_list.getList();
      },
      deep: true,
    },
  },
  methods: {
    async getList({ limit, page, query }) {
      let time_from = null, time_to = null
      if(this.filter.time_range) {
        let time_range = this.filter.time_range.split(' to ');
        if(time_range.length === 2) {
          time_from = new Date(time_range[0]);
          time_to = new Date(time_range[1]);
        }
      }

      let response_data = await service.getList({
        query: JSON.stringify({ ...query }),
        time_from, time_to, page, limit,
        search_key: this.filter.title,
      });
      let list = [], total = 0;
      if (response_data) {
        list = response_data.list;
        total = response_data.total;

        list = list.map(item => {
          return { ...item, status: getStatus(item) }
        });
      }

      return { list, total };
    },

    async deleteItem({ _id }) {
      await service.delete({ id: _id });
      this.$refs.mail_list.getList();
    },

    toEditPage(data) {
      this.editing_data = data;
      this.$refs.mail_update_popup.startEdit();
    },

    async createItem(data) {
      let new_item = await service.create({...data});
      return new_item;
    },
    async updateItem(data) {
      await service.update({
        data: JSON.stringify(data)
      });
      this.$refs.mail_list.getList();
    },
  },
};
</script>
