
export const MAIL_TYPES = {
  NOTIFICATION: 1,
  REWARD_GOGA_RANK: 2,
  WELCOME_NEWBIE_MEMBER: 3,
  REWARD_LOYALTY_REFERRAL: 4,
  REWARD_LOYALTY_BLACKLIST: 5,
  REWARD_LOYALTY_CANCEL: 6,
  REWARD_LOYALTY_OK: 7,
  NEWBIE_AUTO_STAGE_NOTIFY: 8,
  SOCIAL_PLUS_CREDIBILITY_DEDUCTION: 9,
  SOCIAL_PLUS_CREDIBILITY_ADDITION: 10,
  WELCOME_NEWBIE_MEMBER_WITH_GEM: 11,
  NATIVE_TALKSHOW_CANCEL_NON_REFUND: 12,
  NATIVE_TALKSHOW_CANCEL_REFUND_GEM: 13,
  LEAD_INBOUND: 14,
};

export const BODY_TYPE_OPTIONS = [
  { text: "TextMeshPro", value: "text-mesh-pro" },
  { text: "Textarea", value: "text-area" },
];

export const MAIL_STATUS = {
  PRIVATE: 1,
  PUBLIC: 2,
  EXPIRED: 3,
  DEFAULT: 4,
};
